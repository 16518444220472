import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';
import { DataManager } from '../../core/data-manager';
import { ApiManager } from '../../core/api-manager';
import { LanguageProvider } from '../../core/language-provider';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';




export default function DataGridLastTdHook({ item, props, entity = null}) {

    const navigate = useNavigate()

    useEffect(() => {
        
    }, []);


    function goToCourseDetails(item) {
        navigate('/page/CourseDetails?id=' + item.id)
    }
    function goToUserCourse(item) {
        navigate('/page/UserCourse?id=' + item.id)
    }


    async function exportReportPublication(item){
        var body = {
            idPublication: item.id
        }

        var res = await ApiManager.sendAuthenticatedRequest('/custom/export_publication', body);
        console.log(res);
        if(res.success === 1){
            const worksheet = XLSX.utils.json_to_sheet(res.body);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Lista utenti');
            XLSX.writeFile(workbook, item.title + '.xlsx');
        }else {
            toast.error(res.message)
        }
    }


    /* async function exportReportEvent(item){
        var body = {
            idEvent: item.id
        }

        var res = await ApiManager.sendAuthenticatedRequest('/custom/export_event', body);
        console.log(res);
        if(res.success === 1){
            const worksheet = XLSX.utils.json_to_sheet(res.body);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Lista utenti');
            XLSX.writeFile(workbook, item.name + '.xlsx');
        }else {
            toast.error(res.message)
        }
    } */

    async function exportReportCourse(item){
        var body = {
            idCourse: item.id
        }
        var res = await ApiManager.sendAuthenticatedRequest('/custom/export_course', body);
        console.log(res);
        if(res.success === 1){
            const worksheet = XLSX.utils.json_to_sheet(res.body);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Lista utenti');
            XLSX.writeFile(workbook, item.name + '.xlsx');
        }else {
            toast.error(res.message)
        }
    }



    if (entity !== null) {
        return (
            <>
                {(() => {
                    switch(entity) {
                        case 'sample': return (
                            <span>Test</span>
                        );
                        case 'course': return (
                            <>
                                <Button onClick={()=>goToCourseDetails(item)} size={'sm'} variant='outline-secondary'>Gestisci episodi</Button>
                                <Button onClick={()=>goToUserCourse(item)}  size={'sm'} variant='outline-primary' className='ms-1'>Gestisci utenti</Button>
                                <Button onClick={()=>exportReportCourse(item)} size={'sm'} variant='outline-secondary' className='ms-1'>Scarica report</Button>
                            </>
                        );
                        case 'publication': return (
                            <>
                            <Button onClick={()=>navigate('/page/PublicationStats?id=' + item.id)} size={'sm'} variant='outline-secondary'>Vedi statistiche</Button>
                                {/* <Button onClick={()=>exportReportPublication(item)} size={'sm'} variant='outline-secondary'>Scarica report</Button> */}
                            </>
                        );
                        case 'event': return (
                            <>
                                <Button onClick={()=>navigate('/page/EventStats?id=' + item.id)} size={'sm'} variant='outline-secondary'>Vedi statistiche</Button>
                                {/* <Button onClick={()=>exportReportEvent(item)} size={'sm'} variant='outline-secondary'>Scarica report</Button> */}
                            </>
                        );
                        default: return (
                            <></>
                        );
                    }
                })()}
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
};