export const entity_language = {
    entity: {
        user: 'Utenti',
        user_group: 'Gruppi',
        user_entity_saved_filters: 'Filtri salvati',
        user_entity_preferences: 'Preferenze entità',
        log: 'Log di sistema',
        category: 'Categorie',
        path: 'Percorsi',
        course: 'Corsi',
        episode: 'Episodi',
        blog: 'Notizie',
        event: 'Eventi',
        publication: 'Pubblicazioni',
        association: 'Associazioni',
        certificate_template: 'Template certificati'
    },
    labels: {
        "user": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idUserGroup": 'Gruppo',
            "name": 'Nome',
            "surname": 'Cognome',
            "username": 'Username',
            "email": 'Email',
            "level": 'Livello utente',
            "level.options.0": 'admin',
            "level.options.1": 'user',
            "avatar": 'Avatar',
            "password": 'Password',
            "company": 'Azienda',
            "cf": 'Codice fiscale',
        },
        "user_group": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome gruppo',
        },
        "user_entity_saved_filters": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "name": 'Nome',
            "notes": 'Note',
            "content": 'Json filtri'
        },
        "user_entity_preferences": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "content": 'Json preferenze'
        },
        "log": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idItem": 'Id elemento',
            "idUser": 'Utente',
            "entity": 'Entità',
            "operation": 'Operazione',
            "operation.options.0_DELETE": 'Elimina',
            "operation.options.1_EDIT": 'Modifica',
            "operation.options.2_ADD": 'Aggiungi',
            "operation.options.3_LOGIN": 'Login',
            "operation.options.4_LOGOUT": 'Logout',
            "notes": 'Note',
        },
        "category": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome categoria',
            "description": 'Descrizione',
            "image": 'Immagine di copertina'
        },
        "path": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Autore',
            "name": 'Titolo percorso',
            "description": 'Descrizione',
            "idCategory": 'Categoria',
            "public": 'Pubblicato',
            "image": 'Immagine di copertina',
            "hasCertificate": 'Certificati',
            "courses": 'Corsi'
        },
        "path_course": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idPath": 'Percorso',
            "idCourse": 'Corso',
            "position": 'Posizione'
        },
        "course": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Autore',
            "name": 'Titolo corso',
            "description": 'Descrizione',
            "idCategory": 'Categoria',
            "groups": 'Gruppi (con visualizzazione)',
            "inEvidence": 'In evidenza',
            "public": 'Pubblicato',
            "image": 'Immagine di copertina',
            "episodes": 'Episodi',
            "hasCertificate": 'Certificati',
            "documents": 'Documenti',
            "documents_notRegistered": 'Documenti per i non registrati',
            "cost": 'Costo corso',
            "paymentNotes": 'Note pagamento',
            "certificateNotes": 'Note certificato',
            "certificateDescription": 'Descrizione certificato',
            "idAssociation": 'Associazione',
            "estimatedTime": 'Durata corso (ore)',
            "episodesToComplete": 'Min episodi per completare corso'
        },
        "course_documents": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome file',
            "file": 'File'
        },
        "episode": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "description": 'Descrizione',
            "type": 'Tipo episodio',
            "type.options.live": 'Evento live',
            "type.options.video": 'Video',
            "type.options.file": 'File pdf',
            "type.options.survey": 'Test',
            "points": 'Punti al completamento',
            "estimatedMinutes": 'Minuti stimati',
            "image": 'Copertina episodio',
            "position": 'Posizione',
            "episodeDate": 'Data episodio',
            "percentage": 'Percentuale minima completamento'
        },
        "event": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome evento',
            "documents": 'Documenti',
            "idCategory": 'Categoria',
            "description": 'Descrizione',
            "link": 'Link',
            "startDatetime": 'Ora inizio',
            "endDatetime": 'Ora fine',
            "idAssociation": 'Associazione'
        },
        "event_documents": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome file',
            "file": 'File'
        },
        "blog": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Autore',
            "title": 'Titolo',
            "content": 'Contenuto',
            "image": 'Anteprima',
            "file": 'Allegato',
            "public": 'Pubblicato',
        },
        "publication": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Autore',
            "title": 'Titolo',
            "content": 'Contenuto',
            "image": 'Anteprima',
            "file": 'Allegato',
            "public": 'Pubblicato',
            "publicationDate": 'Data pubblicazione',
            "idCategory": 'Categoria'
        },
        "association": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "subname": 'Subnome',
            "logo": 'Logo',
            "description": 'Descrizione',
            "mail": 'Email',
            "website": 'Sito web',
            "phone": 'Telefono',
            "address": 'Indirizzo',
            "googleMapsLink": 'Link Google Maps',
            "contacts": 'Contatti',
        },
        "contact_association": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idAssociation": 'ID Associazione',
            "qualification": 'Qualifica',
            "name": 'Nome',
        },
        "certificate_template": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome file',
            "file": 'File',
            "notes": 'Note',
            "defaultTemplate": 'Default'
        },
    }
};